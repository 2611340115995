<template>
  <div class="">
    <ui-modal v-model="isVisible" class="m-card">
      <div class="m-card__wrp">
        <div class="logo">
          <div class="wrapper-logo">
            <DFLogo color="#fff" />
            <DFLogoMobile />
          </div>
        </div>

        <form @submit.prevent.stop="onSubmit">
          <div v-if="screen === 'login'" class="form">
            <div class="btn btn__back" @click="isVisible = false">
              <ArrowLeftSmall />
              {{ $t('back') }}
            </div>
            <div
              class="text-h6 text-black-100 text-weight-bold form-login-title"
            >
              {{ isOfferPage ? $t('login') : $t('email') }}
            </div>
            <div
              v-if="isOfferPage"
              class="form-login-subtitle-for-offer"
            >
              {{ $t('startCreation') }}
            </div>
            <div
              class="form__wrapper"
              :class="{form__wrapper_reverse: isFirstGoogle}"
            >
              <div class="auth__base">
                <ui-input
                  v-model="state.form.email"
                  :error="vLogin$.form.email.$error"
                  class="input"
                  :placeholder="$t('emailWord')"
                  :clearable="true"
                  @blur="vLogin$.form.email.$touch"
                  @keyup.enter="onSubmit"
                  @keydown.backspace="onBackSpace"
                />
                <ui-input
                  v-model="state.form.password"
                  :error="vLogin$.form.password.$error"
                  type="password"
                  class="input q-pa-sm"
                  :placeholder="$t('pass')"
                  @blur="vLogin$.form.password.$touch"
                  @keyup.enter="onSubmit"
                  @keydown.backspace="onBackSpace"
                />
                <div
                  v-if="
                    vLogin$.form.email.$error ||
                    vLogin$.form.password.$error ||
                    errors
                  "
                  class="error-box"
                >
                  <ErrorIcon
                    style="width: 20px; margin-right: 10px"
                  />
                  <span>
                    {{
                      (vLogin$.form.email.$error
                        ? $t('invalidEmail')
                        : '') ||
                      (vLogin$.form.password.$error
                        ? $t('passRequired')
                        : '') ||
                      (errors && $t(errors))
                    }}
                  </span>
                </div>
                <ui-checkbox v-model="rememberMe" @mousedown.prevent>
                  {{ $t('rememberMe') }}
                </ui-checkbox>
                <ui-button
                  color="primary"
                  class="btn-sub"
                  :disabled="isDisabled"
                  :is-loading="isLoading"
                >
                  {{ $t('login') }}
                </ui-button>
              </div>
              <div class="separat-block">
                <div class="spline" />
                <div class="or">
                  {{ $t('or') }}
                </div>
                <div class="spline" />
              </div>
              <div class="auth__google">
                <div class="external">
                  <div class="auth">
                    <div
                      class="google"
                      @click="loginWithGoogle"
                      @mousedown.prevent
                    >
                      <div class="i" />
                      <div class="text">
                        {{ $t('signInWithGoogle') }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="login-link cursor-pointer"
              @mousedown.prevent
              @click="
                resetErrors();
                screen = 'signup';
              "
            >
              {{ $t('signup') }}
            </div>
            <div
              class="login-link cursor-pointer"
              @mousedown.prevent
              @click="
                resetErrors();
                screen = 'recpas';
                state.form.password = '';
              "
            >
              {{ $t('forgot') }}
            </div>
          </div>

          <!-- SIGN UP MODAL -->
          <div v-else-if="screen === 'signup'" class="form">
            <div class="q-pl-none q-pr-none">
              <div class="text-h6 text-weight-bold form-login-title">
                {{ $t('signup') }}
              </div>
              <div
                v-if="isOfferPage"
                class="form-login-subtitle-for-offer"
              >
                {{ $t('startCreation') }}
              </div>
            </div>
            <div class="q-pt-none q-pb-none q-pl-none q-pr-none">
              <div
                class="form__wrapper"
                :class="{form__wrapper_reverse: isFirstGoogle}"
              >
                <div class="auth__base">
                  <ui-input
                    v-model="state.form.email"
                    :error="v$.form.email.$error"
                    class="input-group input"
                    :clearable="true"
                    :placeholder="$t('emailWord')"
                    @blur="v$.form.email.$touch"
                    @keyup.enter="onSubmit"
                    @keydown.backspace="onBackSpace"
                  />
                  <ui-input
                    v-model="state.form.password"
                    :error="v$.form.password.$error"
                    :type="isPwd ? 'password' : 'text'"
                    class="input q-pa-sm"
                    :placeholder="$t('pass')"
                    @blur="v$.form.password.$touch"
                    @keyup.enter="onSubmit"
                    @keydown.backspace="onBackSpace"
                  />
                  <div
                    v-if="
                      v$.form.email.$error ||
                      v$.form.password.$error ||
                      errors
                    "
                    class="error-box"
                  >
                    <ErrorIcon
                      style="width: 20px; margin-right: 10px"
                    />
                    <span>
                      {{
                        (v$.form.email.$error
                          ? $t('invalidEmail')
                          : '') ||
                        (v$.form.password.$error
                          ? $t('invalidPass')
                          : '') ||
                        (errors && $t(errors))
                      }}
                    </span>
                  </div>
                </div>
                <div class="separat-block" v-if="isOfferPage">
                  <div class="spline" />
                  <div class="or">
                    {{ $t('or') }}
                  </div>
                  <div class="spline" />
                </div>
                <div class="auth__google" v-if="isOfferPage">
                  <div class="external">
                    <div class="auth">
                      <div
                        class="google"
                        @click="loginWithGoogle"
                        @mousedown.prevent
                      >
                        <div class="i" />
                        <div class="text">
                          {{ $t('signUpWithGoogle') }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="checkboxes">
                <ui-checkbox v-model="agreeTermsOfUse">
                  {{ $t('readAndAgree') }}
                  <ui-link
                    to="/terms-of-use"
                    class="link"
                    @click="isVisible = false"
                  >
                    {{ $t('termOfUse') }}
                  </ui-link>
                </ui-checkbox>
                <ui-checkbox v-model="agreePrivacyPolicy">
                  {{ $t('willBeProcessedBy') }}
                  <ui-link
                    to="/privacy-policy"
                    class="link"
                    @click="isVisible = false"
                  >
                    {{ $t('privacyPolicy') }}
                  </ui-link>
                </ui-checkbox>
              </div>
            </div>
            <div class="btn-sub">
              <ui-button
                :label="$t('signup')"
                color="primary"
                no-caps
                :is-loading="isLoading"
                :disabled="
                  isDisabledReg ||
                  !agreeTermsOfUse ||
                  !agreePrivacyPolicy
                "
              />
            </div>
            <div
              class="login-link cursor-pointer"
              @mousedown.prevent
              @click="
                resetErrors();
                screen = 'login';
                state.form.password = '';
              "
            >
              {{ $t('haveAccount') }}
            </div>
          </div>

          <!-- PIN MODAL -->
          <div
            v-else-if="screen === 'confirmPin'"
            class="form center"
          >
            <div
              class="q-pt-none q-pl-none q-pr-none q-pl-none q-pr-none"
            >
              <div
                class="recovery-password__back-btn"
                @click="backFromEmailConfirm"
              >
                <ArrowLeftSmall />{{ $t('back') }}
              </div>
              <div class="text-h6 text-weight-bold">
                {{ $t('emailConf') }}
              </div>
              <div class="sub-text">
                {{ $t('checkEmailForPin') }}
              </div>
            </div>
            <div class="q-pt-none q-pb-none q-pl-none q-pr-none">
              <div class="pin-code-inputs-box">
                <input
                  v-for="(digit, index) in 6"
                  :key="index"
                  ref="digits"
                  v-model="code[index]"
                  type="text"
                  maxlength="1"
                  class="pin-input"
                  @input="handleInput(index)"
                  @keydown.backspace="handleBackspace(index)"
                  @keypress="onlyNumber"
                  @focus="onEnter"
                  @keyup.enter="onSubmit"
                  @paste="fromClipBoard"
                />
              </div>
              <div
                v-if="
                  v$.form.email.$error ||
                  v$.form.password.$error ||
                  errors.length > 1
                "
                class="error-box"
                style="margin-top: 30px"
              >
                <errorIcon style="width: 20px; margin-right: 10px" />
                <span>
                  {{
                    (v$.form.email.$error &&
                      $t(
                        String(v$.form.email.$errors[0].$message),
                      )) ||
                    (v$.form.password.$error &&
                      $t(
                        String(v$.form.password.$errors[0].$message),
                      )) ||
                    (errors && $t(errors))
                  }}
                </span>
              </div>
            </div>
            <div class="btn-sub q-pl-none q-pr-none">
              <ui-button
                :label="$t('signin')"
                color="primary"
                :is-loading="isLoading"
                no-caps
              />
            </div>
          </div>
          <div
            v-else-if="screen === 'confirmEmail'"
            class="form center"
          >
            <div
              class="q-pt-none q-pl-none q-pr-none q-pl-none q-pr-none"
            >
              <div class="text-h6 text-weight-bold">
                {{ $t('succesReg') }}
              </div>
              <div class="sub-text">
                {{ $t('passConfirmLinkSendToEmail') }}
              </div>
            </div>
            <div class="btn-sub q-pl-none q-pr-none">
              <ui-button
                :label="$t('Sign in')"
                color="primary"
                no-caps
                :is-loading="isLoading"
                @click="screen = 'login'"
              />
            </div>
          </div>
          <div v-else-if="screen === 'recpas'" class="form">
            <div class="q-pl-none q-pr-none">
              <div
                class="text-h6 text-weight-bold q-pl-none q-pr-none form-login-title"
              >
                {{ $t('recoverPassword') }}
              </div>
            </div>
            <div class="q-pt-none q-pl-none q-pr-none">
              <ui-input
                v-model="state.form.email"
                :error="v$.form.email.$error"
                class="input q-pa-sm"
                :placeholder="$t('emailWord')"
                @blur="v$.form.email.$touch"
                @keyup.enter="onSubmit"
              />
              <ui-input
                v-model="state.form.password"
                :error="v$.form.password.$error"
                :type="isPwd ? 'password' : 'text'"
                class="input q-pa-sm"
                borderless
                :placeholder="$t('newPassword')"
                @blur="v$.form.password.$touch"
                @keyup.enter="onSubmit"
              />
              <div
                v-if="
                  v$.form.email.$error ||
                  v$.form.password.$error ||
                  errors.length > 1
                "
                class="error-box"
              >
                <errorIcon style="width: 20px; margin-right: 10px" />
                <span>
                  {{
                    (v$.form.email.$error
                      ? $t('invalidEmail')
                      : '') ||
                    (v$.form.password.$error
                      ? $t('invalidPass')
                      : '') ||
                    $t(errors)
                  }}
                </span>
              </div>
            </div>
            <div class="btn-sub q-pl-none q-pr-none">
              <ui-button
                :label="$t('send')"
                color="primary"
                no-caps
                :is-loading="isLoading"
                :disabled="isDisabledRecaps"
              />
            </div>
            <div
              class="login-link cursor-pointer"
              @mousedown.prevent
              @click="
                resetErrors();
                screen = 'signup';
              "
            >
              {{ $t('reg') }}
            </div>
          </div>
          <div
            v-else-if="screen === 'confirmEmailAfterRecPas'"
            class="form center"
          >
            <div
              class="q-pt-none q-pl-none q-pr-none q-pl-none q-pr-none"
            >
              <div
                class="recovery-password__back-btn"
                @click="backFromRecoveryPass"
              >
                <ArrowLeftSmall />{{ $t('back') }}
              </div>
              <div class="text-h6 text-weight-bold q-mb-md">
                {{ $t('recoverPassword') }}
              </div>
              <div class="sub-text">
                {{
                  // $t('passRecoverLinkSendToEmail')
                  $t('checkEmailForReset')
                }}
              </div>
              <div class="q-pt-none q-pb-none q-pl-none q-pr-none">
                <div class="pin-code-inputs-box">
                  <input
                    v-for="(digit, index) in 6"
                    :key="index"
                    ref="digits"
                    v-model="code[index]"
                    type="text"
                    maxlength="1"
                    class="pin-input"
                    @input="handleInput(index)"
                    @keydown.backspace="handleBackspace(index)"
                    @keypress="onlyNumber"
                    @focus="onEnter"
                    @keyup.enter="onSubmit"
                    @paste="fromClipBoard"
                  />
                </div>
                <div
                  v-if="
                    v$.form.email.$error ||
                    v$.form.password.$error ||
                    errors.length > 1
                  "
                  class="error-box"
                  style="margin-top: 30px"
                >
                  <errorIcon
                    style="width: 20px; margin-right: 10px"
                  />
                  <span>
                    {{
                      (v$.form.email.$error &&
                        $t(
                          String(v$.form.email.$errors[0].$message),
                        )) ||
                      (v$.form.password.$error &&
                        $t(
                          String(
                            v$.form.password.$errors[0].$message,
                          ),
                        )) ||
                      (errors && $t(errors))
                    }}
                  </span>
                </div>
              </div>
            </div>
            <div class="btn-sub q-pl-none q-pr-none">
              <ui-button
                :label="$t('login')"
                color="primary"
                :is-loading="isLoading"
                no-caps
                @click="
                  () => {
                    // screen = 'login'
                    // resetPassword()
                    // state.form.password = ''
                    // screen = 'login'
                  }
                "
              />
            </div>
          </div>
        </form>
      </div>
    </ui-modal>
  </div>
</template>

<script lang="ts" setup>
import {useVuelidate} from '@vuelidate/core';
import {sameAs, minLength} from '@vuelidate/validators';
import {initializeApp} from 'firebase/app';
import {
  getAuth,
  GoogleAuthProvider,
  signInWithPopup,
} from 'firebase/auth';
import {useI18n} from 'vue-i18n';
import {ref, computed, reactive, onUpdated} from '#imports';
import DFLogo from '~/components/DFLogo.vue';
import ArrowLeftSmall from '~/assets/svg/arrow-left-small.svg';
import {useUserStore} from '~/modules/User/user.store';
import UiModal from '~/components/Ui/UiModal.vue';
import UiInput from '~/components/Ui/UiInput.vue';
import ErrorIcon from '~/assets/svg/redexclamationmark.svg';
import UiCheckbox from '~/components/Ui/UiCheckbox.vue';
import {useValidators} from '~/hooks/useValidators';
import UiButton from '~/components/Ui/UiButton.vue';
import {
  loginConversion,
  appSignUp,
  authorisationGoogle,
  createEvent,
} from '~/utils/Analytics';
import Logger from '~/helpers/logger';
import {ListErrorRegister} from '~/constants';

const firebaseConfig = {
  apiKey: 'AIzaSyAIAa3upZt5Oc6fLYaLTVFPKPM5Su-bwo0',
  authDomain: 'dreamflat.firebaseapp.com',
  projectId: 'dreamflat',
  storageBucket: 'dreamflat.appspot.com',
  messagingSenderId: '78991832771',
  appId: '1:78991832771:web:0c4142eebabe4e3c80d476',
};
initializeApp(firebaseConfig);

const userLogger = Logger.getInstance();

const route = useRoute();
const isOfferPage = computed(() => route.fullPath.includes('/offer'));

const props = withDefaults(
  defineProps<{
    show?: boolean;
    persistent?: boolean;
    isFirstGoogle?: boolean;
  }>(),
  {
    show: false,
    persistent: false,
    isFirstGoogle: false,
  },
);

const emits = defineEmits(['update:show']);
const {email, required} = useValidators();
const isVisible = computed({
  get() {
    return props.show;
  },
  set(value) {
    emits('update:show', value);
  },
});

const user = useUserStore();

const loginWithGoogle = () => {
  resetErrors();
  const provider = new GoogleAuthProvider();
  signInWithPopup(getAuth(), provider)
    .then(function (result) {
      // Вход выполнен успешно
      const res = result.user;
      let token: string | null = null;
      res
        .getIdToken()
        .then(async (e) => {
          await user
            .loginExternal(res.displayName, e)
            .then((resp) => {
              token = resp.token;
              if (resp.error) {
                errors.value =
                  ListErrorRegister[
                    `STATUS_${resp?.status === 401 ? 'AUTH_' : ''}${
                      resp?.status || 'DEFAULT'
                    }` as keyof typeof ListErrorRegister
                  ];
              }
            });
        })
        .finally(() => {
          authorisationGoogle();
          createEvent(user.user_id, 'authorisation_google', token);
          userLogger.info('[USER] authorisation_google');
        });
    })
    .catch(function (error) {
      console.debug(error);
      userLogger.error('[USER] authorisation_google', error);
    });
};

const state = reactive({
  form: {
    email: '',
    // username: '',
    password: '',
    password2: '',
  },
});

const rules = {
  form: {
    email: {required, email},
    password: {required, minLength: minLength(8)},
    // username: { required },
    password2: sameAs(state.form.password),
  },
};

const rulesLogin = {
  form: {
    email: {required, email},
    password: {required},
  },
};

const isLoading = ref(false);

const code = ref(Array(6).fill(''));
const digits = ref<Array<HTMLInputElement>>([]);

const handleInput = (index: number) => {
  const input = digits.value[index];
  if (input.value.length === 1 && index < 5) {
    digits.value[index + 1].focus();
  }
};

const handleBackspace = (index: number) => {
  if (index > 0 && !code.value[index]) {
    digits.value[index - 1].focus();
  }
};

const onBackSpace = () => {
  v$.value.$reset();
  vLogin$.value.$reset();
};

const fromClipBoard = (event: Event) => {
  event.preventDefault();
  navigator.clipboard.readText().then((copyText) => {
    const codeCopy = copyText
      .replace(/\s/g, '')
      .split('')
      .map((item) => Number(item))
      .filter((item) => typeof item === 'number' && !isNaN(item));
    if (codeCopy.length) {
      for (let i = 0; i < digits.value.length; i++) {
        code.value[i] = String(codeCopy[i]);
      }
      digits.value[digits.value.length - 1].focus();
    } else {
      digits.value[0].focus();
    }
  });
};

const onlyNumber = (e: KeyboardEvent) => {
  // const char = String.fromCharCode(e.keyCode) !!!DEPRECATED
  const char = e.key;
  if (!/^\d*$/.test(char)) {
    e.preventDefault();
  }
};

const onEnter = (event: FocusEvent) => {
  const target = event.target as HTMLInputElement;
  target.classList.add('pin-input_entered');
};

const resetErrors = () => {
  errors.value = '';
  vLogin$.value.$reset();
  v$.value.$reset();
};

watch(
  () => state.form.email,
  (value) => {
    if (!value) {
      vLogin$.value.$reset();
      v$.value.$reset();
    }
  },
);
watch(
  () => state.form.password,
  (value) => {
    if (!value) {
      vLogin$.value.$reset();
      v$.value.$reset();
    }
  },
);

const vLogin$ = useVuelidate(rulesLogin, state);
const v$ = useVuelidate(rules, state);
const rememberMe = ref(true);
const showSignUpModal = ref(false);
const screen = ref('login');
const isPwd = ref(true);
const errors = ref('');

const agreeTermsOfUse = ref(true);
const agreePrivacyPolicy = ref(true);
// const newsletter = ref(false)
const {locale} = useI18n();

const backFromRecoveryPass = () => {
  screen.value = 'recpas';
  code.value = Array(6).fill('');
  errors.value = '';
};

const backFromEmailConfirm = () => {
  screen.value = 'signup';
  code.value = Array(6).fill('');
  errors.value = '';
};

const isDisabled = computed(() => {
  return !(state.form.email && state.form.password);
});

const isDisabledRecaps = computed(() => {
  return !(
    !v$.value.form.password.$error &&
    !v$.value.form.email.$error &&
    state.form.password.length >= 8 &&
    state.form.email.length > 3
  );
});

const isDisabledReg = computed(() => {
  return !(
    !v$.value.form.password.$error &&
    !v$.value.form.email.$error &&
    state.form.password.length >= 8 &&
    state.form.email.length > 3
  );
});

async function onSubmit() {
  errors.value = '';
  isLoading.value = true;

  if (screen.value === 'signup') {
    const response: any = await user.register(
      state.form.email.toLowerCase(),
      'username',
      state.form.password,
    );

    if (response.error) {
      errors.value =
        ListErrorRegister[
          `STATUS_${
            response.status || 'DEFAULT'
          }` as keyof typeof ListErrorRegister
        ];
    } else {
      screen.value = 'confirmPin';
    }
    isLoading.value = false;
    return;
  }

  if (screen.value === 'recpas') {
    const response: any = await user.recover(
      state.form.email,
      state.form.password,
    );

    isLoading.value = false;

    if (response.error) {
      errors.value =
        ListErrorRegister[
          `STATUS_${
            response.status || 'DEFAULT'
          }` as keyof typeof ListErrorRegister
        ];
    } else {
      screen.value = 'confirmEmailAfterRecPas';
    }
    return;
  }

  if (screen.value === 'login') {
    if (!(await vLogin$.value.form.$validate())) {
      /* empty */
    } else {
      const response = await user.login(
        state.form.email.toLowerCase(),
        state.form.password,
      );
      isLoading.value = false;

      if (response.error) {
        errors.value =
          ListErrorRegister[
            `STATUS_${response.status === 401 ? 'AUTH_' : ''}${
              response.status || 'DEFAULT'
            }` as keyof typeof ListErrorRegister
          ];
      } else {
        loginConversion();
        state.form.email = '';
        state.form.password = '';
        vLogin$.value.$reset();
        v$.value.$reset();
        if (route.redirectedFrom) document.body.scrollIntoView(true);
      }
      showSignUpModal.value = false;
    }
    isLoading.value = false;
    return;
  }

  if (screen.value === 'confirmPin') {
    const result = Object.values(code.value).join('');
    const response: any = await user.verifyEmail(
      state.form.email,
      result,
    );
    isLoading.value = false;

    if (response.error || response.payload.error) {
      errors.value =
        ListErrorRegister[
          `STATUS_${
            response.status || 'DEFAULT'
          }` as keyof typeof ListErrorRegister
        ];
      userLogger.error('[USER] verifyEmail()', errors.value);
    } else {
      userLogger.info('[USER] verifyEmail()');
      screen.value = 'login';
      code.value = Array(6).fill('');
    }
    return;
  }
  if (screen.value === 'confirmEmailAfterRecPas') {
    const result = Object.values(code.value).join('');
    const response: any = await user.verifyPassword(
      state.form.email,
      result,
    );
    isLoading.value = false;

    if (response.error) {
      errors.value =
        ListErrorRegister[
          `STATUS_${
            response.status || 'DEFAULT'
          }` as keyof typeof ListErrorRegister
        ];
    } else {
      screen.value = 'login';
      code.value = Array(6).fill('');
    }
    isLoading.value = false;
    return;
  }
}

onUpdated(() => {
  if (isOfferPage.value) {
    screen.value = 'signup';
  }
  if (showSignUpModal.value) {
    showSignUpModal.value = false;
  } else if (screen.value === 'recpas') {
    screen.value = 'login';
  }
});
</script>

<style lang="scss" scoped>
.recovery-password {
  &__back-btn {
    position: absolute;
    top: 10px;
    left: 24px;
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
    @include title-fs-14-fw-600-gray-100;
  }
}
.input {
  margin-bottom: 16px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #3d424a;
}
.login-link {
  color: #2196f3;
  margin-top: $m-4;
  & + & {
    margin-top: 12px;
  }
}
.error-box {
  background: #fbebe7;
  padding: 12px;
  border: 1px solid #e99b88;
  border-radius: 8px;
  font-weight: 600;
  font-size: 14px;
  height: 44px;
  margin-bottom: 19px;
  display: flex;
  align-items: center;
}

.m-card__wrp {
  background: linear-gradient(
    165.47deg,
    #1e90ea -6.68%,
    #005ba4 111.75%
  );
  display: flex;
  min-width: 375px;
  position: relative;

  .logo {
    .wrapper-logo {
      width: 50%;
      .main-logo-mobile {
        display: none;
      }

      @media (max-width: $lg) {
        .main-logo-mobile {
          display: flex;
        }
        .main-logo {
          display: none;
        }
      }
    }

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    color: #fff;
    width: 150px;
    margin-top: 30px;

    @media (max-width: $lg) {
      margin-top: 5%;
    }
  }

  .form {
    position: relative;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: #fff;
    padding: 26px 24px;
    width: 350px;

    &__wrapper {
      display: flex;
      flex-direction: column;
      margin-bottom: $m-1;

      &_reverse {
        flex-direction: column-reverse;
      }

      .separat-block {
        display: flex;
        justify-content: space-between;
        gap: $g-5;
        align-items: center;
        margin-top: $m-6;
        margin-bottom: $m-6;
        margin-left: $m-1;
        margin-right: $m-1;
        .spline {
          height: 1px;
          border-radius: $br-10;
          width: 48%;
          background: var(--var-gray-100);
        }

        .or {
          @include launcher-p3;
          color: var(--var-black-100);
        }
      }
    }
    .btn__back {
      margin-top: -3px;
      margin-bottom: 8px;
      display: flex;
      align-items: center;
      color: var(--var-gray-100);

      svg {
        margin-right: 5px;
        width: 15px;
      }
      @media (min-width: $lg) {
        display: none;
      }
    }
    .external {
      .border {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .spline {
          height: 1px;
          width: 100%;
          background-color: #b2c0cd;
        }
        .or {
          margin: 0 16px;
        }
      }
      .auth {
        .google {
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 8px;
          border: 1px solid var(--white-theme-white-400, #e1e5f0);
          color: var(--white-theme-black-200, #64686e);
          /* Button/S */
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          letter-spacing: 0.28px;
          padding: 7px;
          .i {
            margin-right: 8px;
            background-image: url(assets/svg/google.svg);
            width: 18px;
            height: 18px;
          }
        }
      }
    }

    .q-field {
      margin-bottom: 16px;
    }

    .form-login-title {
      padding: 0;
      margin: 0 0 24px 0;
      font-size: 18px;
    }
    .form-login-subtitle-for-offer {
      color: var(--white-theme-black-200, #64686e);
      font-family: Segoe UI;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.28px;
      margin-bottom: 24px;
      margin-top: -20px;
    }

    .icon-wrapper {
      width: 18px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .icon-custom {
      cursor: pointer;
      width: 18px;
    }

    &.center {
      justify-content: center;

      .sub-text {
        //margin-top: 24px;
        margin-bottom: 30px;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #3d424a;
      }
    }

    .q-field__control {
      height: 40px;
    }

    .ui-checkbox {
      margin-bottom: 15px;
      margin-top: 8px;
      align-items: flex-start;
      color: #3d424a;
    }

    .button {
      width: 100%;
    }

    .q-checkbox__bg {
      border: 1px solid #b2c0cd;
      border-radius: 4px;
    }

    .q-checkbox__bg > * {
      padding: 4px;
    }

    .q-checkbox__inner {
      margin-top: -10px;
      margin-left: -10px;
    }

    a {
      color: $primary;
      text-decoration: none;
    }

    .q-field--error {
      .q-icon.text-negative.notranslate.material-icons {
        display: none;
      }

      .q-field__control {
        border: 1px solid #d24827;
      }
    }

    .q-field__control {
      display: flex;
      align-items: center;
      overflow: hidden;
      background: #f4f6fd;
      padding-right: 8px;
      border-radius: 8px;
    }

    .input {
      color: #839aaf;
      border-radius: 8px;
      //margin-bottom: 25px;
      padding: 0 12px;
    }

    .input:-webkit-autofill,
    .input:-webkit-autofill:hover,
    .input:-webkit-autofill:focus,
    .input:-webkit-autofill:active {
      -webkit-text-fill-color: #839aaf !important;
      -webkit-box-shadow: 0 0 0 30px #f4f6fd inset;
    }

    .checkboxes {
      font-size: 12px;
    }
  }
}

.back-btn {
  .q-btn {
    max-width: 90px;
  }
}

.btn-sub {
  margin: 24px 0px 0px;
  .q-btn {
    width: 100%;
    border-radius: 8px;
  }
}

.reg-text {
  max-width: 286px;
}

.pin-code-inputs-box {
  display: flex;
  justify-content: space-between;
  .pin-input {
    background: #f4f6fd;
    width: 40px;
    height: 40px;
    border-radius: 8px;
    padding: 8px 10px !important;
    font-weight: 700;
    font-size: 18px;
    color: #3d424a;
    text-align: center;
    border: none;
  }
  .pin-input:focus-visible {
    outline: 1px solid #3d424a;
  }
  .pin-input_entered {
    border: 1px solid #e1e5f0 !important;
  }
}

.pin-code-inputs-box_error {
  .pin-input {
    border: 1px solid #d24827;
  }
}

@media (max-width: $lg) {
  .m-card {
    .m-card__wrp {
      width: 100%;
      max-height: 100% !important;
      height: 100%;
      flex-direction: column;

      .logo {
        max-height: 100%;
        min-height: 140px;
      }
    }

    .form {
      width: 100%;
      height: auto;

      .q-card__actions {
        margin-top: 20px;
      }
    }
  }

  .q-dialog__inner--minimized {
    padding: 0px;
  }
}

@media (max-width: $lg) {
  .m-card {
    .m-card__wrp {
      width: 100%;
      max-height: 100% !important;
      height: 100%;
      flex-direction: column;
      min-width: 320px;

      .logo {
        max-height: 100%;
        height: 140px;
      }
    }

    .form {
      width: 100%;
      height: auto;
      border-top-left-radius: 32px;
      border-top-right-radius: 32px;

      .q-card__actions {
        margin-top: 20px;
      }
      .user-login-close {
        display: block;
        margin-bottom: 24px;
        &__button {
          display: flex;
          gap: 10px;
          font-weight: 600;
          font-size: 14px;
          line-height: 19px;
          letter-spacing: 0.02em;
          color: #b2c0cd;
          img {
            rotate: -90deg;
          }
        }
      }
      .hide {
        display: none;
      }
    }
  }

  .q-dialog__inner--minimized {
    padding: 0px;
  }
}
@media (max-width: $md) {
  .m-card {
    .m-card__wrp {
      width: 100%;
      max-height: 100% !important;
      height: 100%;
      flex-direction: column;
      min-width: 100%;

      .logo {
        max-height: 100%;
        height: 150px;
        min-height: 140px;
      }
    }

    .form {
      width: 100%;
      height: auto;
      border-top-left-radius: 32px;
      border-top-right-radius: 32px;

      .q-card__actions {
        margin-top: 20px;
      }
      .user-login-close {
        display: block;
        margin-bottom: 24px;
        &__button {
          display: flex;
          gap: 10px;
          font-weight: 600;
          font-size: 14px;
          line-height: 19px;
          letter-spacing: 0.02em;
          color: #b2c0cd;
          img {
            rotate: -90deg;
          }
        }
      }
      .hide {
        display: none;
      }
    }
  }

  .q-dialog__inner--minimized {
    padding: 0px;
  }
}
</style>
